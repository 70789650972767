const { default: Swiper, A11y, Autoplay, Navigation, Pagination } = require("swiper");

Swiper.use([Navigation, Pagination, Autoplay, A11y]);

function reInitEinsteinCarousel() {
  var $carouselContainers = $('.einstein-products-carousel');
  
  // Einstein Carousel configs, based on BaseCarousel 
  const configs = {
    slidesPerView: 5,
    slidesPerGroup: 5,
    centeredSlides: false,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    watchOverflow: true,
    breakpoints: {
      320: {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
      },
      1024: {
        slidesPerView: 5,
        slidesPerGroup: 5,
      },
    },
  };

  var handleEinsteinMutations = function(mutationList, observer) {

    for (var i = 0; i < mutationList.length; i++) {
      var mutation = mutationList[i];

      if (mutation.type == 'childList') {
        let recommendationSwiperMainWrapper;

        if ($(mutation.target).attr('id') && $(mutation.target).attr('id').indexOf('cq_recomm_slot') !== -1) {

          recommendationSwiperMainWrapper = mutation.target.children && mutation.target.children.length 
          ? Array.from(mutation.target.children).filter((childNode, index) => $(childNode).hasClass('recommendation-swiper'))[0]
          : null;

        } else if ($(mutation.target).hasClass('einstein-products-carousel')) {
          // Set new observer..
          // This is needed in order to detect container injection with id #cq_recomm_slot

          var observer = new MutationObserver(handleEinsteinMutations);
          observer.observe(mutation.target, {childList: true, subtree: true});

          continue;
        }

        // Restart init when main swiper wrapper has been added to DOM
        if (recommendationSwiperMainWrapper) {
          new Swiper(recommendationSwiperMainWrapper, configs);
        } else {
          new Swiper ('.recommedation-wrapper', configs);
        }

        // Disconnect observer once swiper has been reinitialized
        observer.disconnect();
      }
    }
  };

  var observer = new MutationObserver(handleEinsteinMutations);
    
  // Observe all Einstein Carousels mutations
  $carouselContainers.each(function(index, cont) {
    observer.observe(cont, {childList: true, subtree: true});
  });
}

module.exports = {
  reInitEinsteinCarousel
};