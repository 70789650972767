function updateImpressionList(impressionID, list) {
  if (!impressionID || !list || !__dl.cardImpressions[impressionID]) return false;
  __dl.cardImpressions[impressionID].list = list;
  return true;
}

function dtlCardData(cardData, currentList, position) {
  if (!cardData || !cardData.cardtype) return null;
  let dtl = cardData.productcard || {};
  dtl.position = position || 1;
  dtl.list = currentList || '';
  // dtl.dimension88 = cardData.cardtype;
  // if (cardData.cardtype === 'product_std') {
  //   if (!dtl.id) return null;
  // } else {
  //   dtl.name = dtl.name || dtl.dimension88;
  //   dtl.id = dtl.id || dtl.dimension88;
  //   dtl.category = cardData.category || dtl.dimension88;
  // }
  return dtl;
}

function pushImpression(cards, rifinements) {
  rifinements = rifinements || {};
  dataLayer.push({
    event: rifinements.event || 'productImpression',
    ecommerce: {
      currencyCode: rifinements.currencyCode || window.currencyCode || window.dataLayerPersonalData.currency || '',
      impressions: cards,
    },
  });
}

function processImpressions(impressionLists, rifinements) {
  if (!impressionLists || !impressionLists.length) return false;
  impressionLists.forEach(function (list) {
    if (list.length < 16) {
      pushImpression(list, rifinements);
    } else {
      let i,
        j = list.length,
        chunk = 15;
      for (i = 0; i < j; i += chunk) {
        pushImpression(list.slice(i, i + chunk), rifinements);
      }
    }
  });
}

function trackClick(impressionID, _url) {
  if (!impressionID) return false;
  let _impr = __dl.cardImpressions && __dl.cardImpressions[impressionID];
  if (!_impr) return false;
  let dtl = Object.assign({}, _impr);
  let list = dtl.list;
  delete dtl.list;
  console.log(impressionID, 'click:', dtl);
  dataLayer.push({
    event: 'productClick',
    ecommerce: {
      currencyCode: window.currencyCode || window.dataLayerPersonalData.currency || '',
      click: {
        actionField: {
          list: list,
        },
        products: [dtl],
      },
    },
    eventCallback: function () {
      document.location = _url;
    },
  });
}

function checkForNewImpressions($region, force, dlRifinements) {
  var impressionLists = [],
    cardList,
    position,
    dlRifinements = dlRifinements || {},
    force = force || false,
    //verifico se esiste una regione e se esiste verifico se è un parent o se è l'elemento da gestire
    $selector = $region ? ($region.filter('[data-impressionid]').length && $region) || $region.find('[data-impressionid]') : $('[data-impressionid]'),
    _list = [];

  $selector.each(function (i, el) {
    let $el = $(el);
    let cardData = $el.data();
    if (!cardData.cardtype || !cardData.impressionid) return true;

    //verifico di non avere una card già in "pancia" - ATTENZIONE impressionid duplicati
    let impr = __dl.cardImpressions[cardData.impressionid];

    let $parent = $el.parents('[data-listcontext]'),
      //cerco di recuperare l'utlima positione un cache per questo elemento
      cachePosition = force ? 0 : $parent.data('listposition') || 0,
      currentList = (!impr || force ? $parent.data('listcontext') : impr.list) || '';

    //verifco se processare o solo pre-processare l'impression
    let processing = force || (!cardData.delayimpression && !impr);
    let preprocessing = processing || !impr;

    if (preprocessing) {
      if (currentList !== cardList) {
        // processo solo se richiesto
        _list = [];
        if (processing) impressionLists.push(_list);
        position = cachePosition + 1;
      } else {
        position++;
      }

      cardList = currentList;
      $parent.data('listposition', position);

      let dtl = dtlCardData(cardData, currentList, position);
      if (!dtl) return true;
      __dl.cardImpressions[cardData.impressionid] = dtl;

      // processo solo se richiesto
      if (processing) _list.push(dtl);
    }
  });
  if (impressionLists.length) processImpressions(impressionLists, dlRifinements.impression);
}

module.exports = {
  // clic: () => {
  //   $('[data-gtm-click]').on('click', () => {
  //     let base = {
  //       event: 'interaction',
  //       eventCategory: 'clic'
  //     };
  //     let dataGtm = JSON.parse($(this).data('gtm-click'));
  //     dataLayer.push({
  //       ...base,
  //       ...dataGtm
  //     });
  //   });
  // },

  pushEventsOnLoad: function () {
    window.dataLayer = window.dataLayer || [];
    if ('__dl' in window && window.__dl && window.__dl.pageload) {
      [].concat(window.__dl.pageload).forEach(function (event) {
        if (event && Object.keys(event).length > 0) {
          dataLayer.push(event);
        }
      });
    }
    window.__dl = window.__dl || {};
    window.__dl.cardImpressions = {};
    window.__dl.checkForNewImpressions = checkForNewImpressions;
    window.__dl.pushImpression = pushImpression;
  },

  handleAjaxDataLayer: function () {
    $.ajaxPrefilter(function (options, originalOptions, jqXhr) {
      let originalSuccess = options.success;
      options.success = function (data) {
        if (data && data.dataLayer) {
          dataLayer.push(data.dataLayer);
        }
        originalSuccess && originalSuccess.apply(jqXhr, arguments);
      };
    });
  },

  header: function () {
    [
      {
        selector: '.logo-home',
        on: 'click',
        eventLabel: 'logo',
        eventLabel2: 'logo',
      },
      {
        selector: '.icon-search',
        on: 'click',
        eventLabel: 'navigation states',
        eventLabel2: 'search',
      },
      {
        selector: '.nav-item--wishlist a',
        on: 'click',
        eventLabel: 'navigation states',
        eventLabel2: 'wishlist',
      },
      {
        selector: '.nav-item--login a',
        on: 'click',
        eventLabel: 'navigation states',
        eventLabel2: 'account',
      },
      {
        selector: '.nav-item--minicart',
        on: 'click',
        eventLabel: 'navigation states',
        eventLabel2: 'minicart',
      },
    ].forEach(function (e) {
      $(e.selector).on(e.on, function () {
        dataLayer.push({
          event: 'interaction',
          eventCategory: 'clic',
          eventAction: 'header',
          eventLabel: e.eventLabel,
          eventLabel2: e.eventLabel2,
        });
      });
    });

    // TODO voice and image search
  },

  menu: function () {
    $('#menu a.menu-item[href]').on('click', function () {
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'menu',
        eventLabel: $(this).hasClass('menu-item-1-liv') ? 'firstLevel' : 'secondLevel',
        eventLabel2: $(this).data('gtm-label2') || $(this).text(),
      });
    });
  }.bind(this),

  account: function () {
    $('form.login').one('submit', function () {
      let loginUrl = $(this).attr('action');
      $.ajaxPrefilter(function (options) {
        let originalSuccess = options.success;
        if (options.url === loginUrl) {
          options.success = function (data) {
            if (data.success) {
              dataLayer.push({
                event: 'interaction',
                eventCategory: 'clic',
                eventAction: 'login & registration',
                eventLabel: 'login',
                eventLabel2: 'confirmed',
              });
            }
            originalSuccess && originalSuccess.apply(this, arguments);
          };
        }
      });
    });

    $('.btn-link[href$=register]').one('click', function () {
      return dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'login & registration',
        eventLabel: 'sign up',
        eventLabel2: 'step 1',
      });
    });

    $('form.registrationStep0').one('submit', function () {
      let registration0Url = $(this).attr('action');

      $.ajaxPrefilter(function (options0) {
        let originalSuccess0 = options0.success;

        if (options0.url === registration0Url) {
          options0.success = function (data) {
            originalSuccess0 && originalSuccess0.apply(this, [].slice.call(arguments));

            if (data && data.success) {
              let $formRegistrationStep1 = $('form.registrationStep1');
              if ($formRegistrationStep1.length > 0) {
                dataLayer.push({
                  event: 'interaction',
                  eventCategory: 'clic',
                  eventAction: 'login & registration',
                  eventLabel: 'sign up',
                  eventLabel2: 'step 2',
                });
              }

              $formRegistrationStep1.one('submit', function () {
                let registration1Url = $(this).attr('action');

                $.ajaxPrefilter(function (options1) {
                  let originalSuccess1 = options1.success;

                  if (options1.url === registration1Url) {
                    options1.success = function (data1) {
                      if (data1 && data1.success) {
                        dataLayer.push({
                          event: 'interaction',
                          eventCategory: 'clic',
                          eventAction: 'login & registration',
                          eventLabel: 'sign up',
                          eventLabel2: data.redirectUrl && data.redirectUrl.indexOf('registrationResult') >= 0 ? 'sent' : 'confirmed',
                        });
                      }
                      originalSuccess1 && originalSuccess1.apply(this, [].slice.call(arguments));
                    };
                  }
                });
              });
            }
          };
        }
      });
    });

    if (location.search && location.search.indexOf('registrationResult=REGISTERED') > 0) {
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'login & registration',
        eventLabel: 'sign up',
        eventLabel2: 'confirmed',
      });
    }
  }.bind(this),

  footer: function () {
    let pushDaralayer = function (data) {
      dataLayer.push(
        Object.assign(
          {
            event: 'interaction',
            eventCategory: 'clic',
            eventAction: 'footer',
            eventLabel: '',
            eventLabel2: '',
          },
          data
        )
      );
    };
    $('footer').on('click', 'a.footer-link', function (e) {
      let link = $(this);
      let label1 = link.parents('[data-gtm-label1]').data('gtm-label1') || '';
      pushDaralayer({
        eventLabel: label1,
        eventLabel2: link.data('gtm-label2') || link.text(),
      });
    });

    $('.country-selector-trigger button').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'country & language',
        eventLabel2: $(this).text(),
      });
    });

    $('.footer-social').on('click', 'a', function (e) {
      pushDaralayer({
        eventLabel: 'social',
        eventLabel2: $(this).text().toLowerCase(),
      });
    });

    $('.nl-subscription-form').on('nl-submitted', function (e) {
      pushDaralayer({
        eventLabel: 'newsletter',
        eventLabel2: 'sent',
      });
    });
  }.bind(this),

  popup: function () {
    $(document).on('shown.bs.modal', '.modal', function () {
      let modal = $(this);
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'impression',
        eventAction: 'popup',
        eventLabel: modal.attr('id'),
      });

      switch (modal.attr('id')) {
        case 'countryLanguageModal':
          $('.country-selector-lang').on('click', 'a', function () {
            let link = $(this);
            dataLayer.push({
              event: 'interaction',
              eventCategory: 'clic',
              eventAction: 'popup',
              eventLabel: 'countryLanguageModal',
              eventLabel2: link.data('locale'),
            });
          });
          break;
        case 'countrySelectorModal':
          $('.country-selector-list').on('click', 'a', function () {
            let link = $(this);
            dataLayer.push({
              event: 'interaction',
              eventCategory: 'clic',
              eventAction: 'popup',
              eventLabel: 'countrySelectorModal',
              eventLabel2: link.data('locale'),
            });
          });
          break;
      }
    });
  }.bind(this),

  filters: function () {
    $(document).on('search:layout', function (e, data) {
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'toggle layout',
        eventLabel: 'toggle layout',
        eventLabel2: data,
      });
    });

    let pushFilter = function () {
      let refinements = $('.refinement-selected')
        .toArray()
        .map(function (el) {
          return {
            filter: $(el).data('refinement-id'),
            value: $(el).data('refinement-value'),
          };
        })
        .reduce(function (acc, ref) {
          if (!(ref.filter in acc)) {
            acc[ref.filter] = [ref.value];
          } else {
            acc[ref.filter].push(ref.value);
          }
          return acc;
        }, {});

      dataLayer.push({
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'filter',
        eventLabel: Object.keys(refinements)
          .map(function (key) {
            return `${key}:${refinements[key].join(',')}`;
          })
          .join('_'),
        eventLabel2: 'sort:' + ($('.refinement-sort-by input:checked').attr('id') || 'none'),
      });
    };

    $(document).on('search:sort', pushFilter);
    $(document).on('search:filterSuccess', pushFilter);
  },

  pdp: function () {
    [
      {
        selector: '#productLocatorPopupTrigger',
        on: 'click',
        eventLabel: 'omnichannel',
        eventLabel2: 'reserve in store',
      },
      {
        selector: '#descriptionPopupTrigger',
        on: 'click',
        eventLabel: 'tab product presentation',
        eventLabel2: 'description',
      },
      {
        selector: '#deliveryPopupTrigger',
        on: 'click',
        eventLabel: 'tab product presentation',
        eventLabel2: 'delivery and returns',
      },
      {
        selector: '.customer-service button',
        on: 'click',
        eventLabel: 'tab product presentation',
        eventLabel2: 'customer service',
      },
      // inserire elementi legati alla voci interne della tab customer service avranno 'eventLabel': 'tab product presentation – customer
      // services'.
      {
        selector: '.product-gallery .carousel-item a',
        on: 'click',
        eventLabel: 'top product carousel',
        eventLabel2: 'image-zoom',
      },
    ].forEach(function (e) {
      $(e.selector).on(e.on, function () {
        dataLayer.push({
          event: 'interaction',
          eventCategory: 'eCommerce',
          eventAction: 'product exploration',
          eventLabel: e.eventLabel,
          eventLabel2: e.eventLabel2,
        });
      });
    });
  },

  plp: function () {
    [
      {
        selector: '.product-card-swatch',
        on: 'click',
        eventAction: 'card',
        eventLabel: 'colour',
        eventLabel2: 'choose',
      },
      {
        selector: '.product-card .js-size-selector',
        on: 'click',
        eventAction: 'card',
        eventLabel: 'size',
        eventLabel2: 'choose',
      },
    ].forEach(function (e) {
      $(e.selector).on(e.on, function () {
        dataLayer.push({
          event: 'interaction',
          eventCategory: 'eCommerce',
          eventAction: e.eventAction,
          eventLabel: e.eventLabel,
          eventLabel2: e.eventLabel2,
        });
      });
    });
  },

  checkout: function () {
    $(document).on('checkout:step', function (e, step) {
      if (dataLayer && step) {
        let eventCheckout = dataLayer.filter(function (e) {
          return e.event === 'checkout' && e.ecommerce && e.ecommerce.checkout && e.ecommerce.checkout.actionField;
        });
        if (eventCheckout.length > 0) {
          let newEvent = { ...eventCheckout[0] };
          newEvent.ecommerce.checkout.actionField.step = step;
          dataLayer.push(newEvent);
        }
      }
    });
  },

  pdStaticContent: function () {
    let baseConf = {
      on: 'click',
      dtl: {
        event: 'interaction',
        eventCategory: 'clic',
        eventAction: 'carousel',
      },
    };
    [
      {
        //BLAND DIFFERENT
        selector: '.blend-different-slider_wrapper .slick-arrow',
        dtl: {
          eventLabel: 'blend different',
          eventLabel2: 'arrow',
        },
      },
      {
        //SHOP BY COLOR
        selector: '.shop-by-colour .slick-arrow',
        dtl: {
          eventLabel: 'shop by colour',
          eventLabel2: 'arrow',
        },
      },
      {
        on: 'start',
        selector: '.shop-by-colour',
        dtl: {
          eventLabel: 'shop by colour',
          eventLabel2: 'start from here',
        },
      },
      {
        on: 'wheelslice',
        selector: '.shop-by-colour',
        dtl: {
          eventLabel: 'shop by colour',
          eventLabel2: 'color',
        },
      },
      // {
      //   on: 'playagain',
      //   selector: '.shop-by-colour',
      //   dtl: {
      //     eventLabel: 'shop by colour',
      //     eventLabel2: 'play again'
      //   }
      // },
      {
        on: 'filters',
        selector: '.shop-by-colour',
        dtl: function (e, event, _this) {
          return {
            eventLabel: 'shop by colour',
            eventLabel2: ($(_this).data('cid') || 'filter').toLowerCase(),
          };
        },
      },
      {
        //MIX AND MATCH
        selector: '.content-mix-and-match .filter',
        dtl: {
          eventLabel: 'mix & match',
          eventLabel2: 'filter',
        },
      },
      {
        //MIX AND MATCH
        selector: '.content-mix-and-match .slick-arrow',
        dtl: {
          eventLabel: 'mix & match',
          eventLabel2: 'arrow',
        },
      },
      {
        //CARUSEL SLIDE
        selector: '.hero-carousel__slide a',
        dtl: {
          event: 'promoClick',
          eventCategory: 'clic',
          eventAction: 'banner',
          eventLabel: 'hero carousel',
          eventLabel2: '',
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  name: 'hero carousel',
                  creative: '',
                },
              ],
            },
          },
        },
      },
    ].forEach(function (e) {
      $('body').on(e.on || baseConf.on, e.selector, function (event) {
        try {
          let dtl = typeof e.dtl === 'function' ? e.dtl(e, event, this) : e.dtl;
          dtl = Object.assign({}, baseConf.dtl, e.dtl);
          dataLayer.push(dtl);
        } catch (e) {
          console.log('datalayer: error on event processing');
        }
      });
    });
  }.bind(this),

  cards: function () {
    $('body').on('click', 'product-card-swatch', function (e) {
      dataLayer.push({
        event: 'interaction',
        eventCategory: 'eCommerce',
        eventAction: 'card',
        eventLabel: 'colour',
        eventLabel2: 'choose',
      });
    });

    $('body').on('click', '[data-cardtype]:not(.o-card-article) a', function (e) {
      let impressionID = $(this).parents('[data-impressionid]').data('impressionid');
      trackClick(impressionID, $(this).attr('href'));
    });

    $('body').on('datalinkclick', '.o-card-article', function (e) {
      let data = $(this).data();
      trackClick(data.impressionid, data.link);
    });

    $('.blend-different-slider_wrapper').on('datalinkclick', '.single-slide', function (e) {
      let data = $(this).data();
      trackClick(data.impressionid, data.link);
    });

    checkForNewImpressions();
  }.bind(this),

  customerservice: function () {
    let pushDaralayer = function (data) {
      dataLayer.push(
        Object.assign(
          {
            event: 'interaction',
            eventCategory: 'clic',
            eventAction: 'customer services',
            eventLabel: '',
            eventLabel2: '',
          },
          data
        )
      );
    };
    $('.customerservice-head .nav-link').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'tab',
        eventLabel2: $(this).attr('id').toLowerCase(),
      });
    });

    $('#tabs-Faq .nav-link').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'faqs',
        eventLabel2: $(this).attr('href').replace('#', '').toLowerCase(),
      });
    });

    $('#pills-Faq .collapse-wrapper .title').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'faqs',
        eventLabel2: $(this).text().toLowerCase(),
      });
    });

    $('#tabs-sizeguide .nav-link').on('click', function (e) {
      pushDaralayer({
        eventLabel: 'size guide',
        eventLabel2: $(this).attr('href').replace('#', '').toLowerCase(),
      });
    });

    $('.trackorder').on('submit', function (e) {
      pushDaralayer(
        $('[page-id=return]').length > 0
          ? {
              eventLabel: 'returns',
              eventLabel2: 'step1',
            }
          : {
              eventLabel: 'order status',
              eventLabel2: 'view order',
            }
      );
    });

    $('[data-pageid=return]').on('click', '.slick-arrow', function (e) {
      pushDaralayer({
        eventLabel: 'returns',
        eventLabel2: 'explanation arrow',
      });
    });

    $('.contact-us').on('submitted', function (e) {
      pushDaralayer({
        eventLabel: 'contact us',
        eventLabel2: 'sent',
      });
    });

    $('body').on('datalinkclick', '.widget-customerservice-list-item', function (e) {
      let elementName = $(this).data('link').indexOf('tel') == 0 ? 'tel' : 'email';
      pushDaralayer({
        eventLabel: 'contact us',
        eventLabel2: elementName,
      });
    });
  }.bind(this),

  delayedImpressions: function () {
    let $blendSlider = $('.blend-different-slider_wrapper .carousel');
    $blendSlider.each(function (i, el) {
      let $el = $(el);
      let listname = 'blend different top';
      if (i > 0) {
        listname = 'blend different bottom';
      }
      $el.parents("[data-listcontext='blend different']").data('listcontext', listname);

      $el.find('[data-impression]').each(function (_x, impEl) {
        let imprID = $(impEl).data('[data-impressionid]');
        updateImpressionList(imprID, listname);
      });

      $el.on('init', function (e, slick) {
        let $activeSlide = $(slick.$slides[0]);
        checkForNewImpressions($activeSlide, true);
      });
    });

    $('.shop-by-colour').on('start', function () {
      setTimeout(
        function () {
          let $activeSlide = $(this).find('.layer--visible .slick-active');
          checkForNewImpressions($activeSlide, true);
        }.bind(this),
        1000
      );
    });

    $('.shop-by-colour').on('afterChange', function () {
      let $activeSlide = $(this).find('.layer--visible .slick-active');
      checkForNewImpressions($activeSlide, true);
    });

    //need ispiration CARUSEL BUILD
    $('body').on('carousels:build', function (e) {
      let $ni = $("[data-listcontext='NeedInspiration]");
      if ($ni.length) checkForNewImpressions($ni, true);
    });

    //category selector
    $('.subcategories_withicons_wrapper .carousel').on('init', function (event, slick, currentSlide, nextSlide) {
      const $slides = $(this).find('.slick-slide:not(.slick-cloned)');
      checkForNewImpressions($slides, true);

      //track click on elements with data-link
      $slides.find('[data-link]').on('click', function () {
        let impression = $(this).closest('[data-impressionid]');
        let $link = $(this).data('link');
        if (impression.length) trackClick(impression.data('impressionid'), $link);
      });
    });
  }.bind(this),
};
